const axios = require('axios');

const config = {};

config.production = {};
config.production.apiUrl = 'https://api.e-asis.com/api/v2';
config.production.apiUrlPublic = 'https://api.e-asis.com/api/v2/public';
config.production.apiImageStatic = 'https://api.e-asis.com/static';
config.production.storeUrl = 'https://api.e-asis.com/';

config.production.port = 9000;

config.development = {};


 config.development.apiUrl = 'https://api.e-asis.com/api/v2';
 config.development.apiUrlPublic = 'https://api.e-asis.com/api/v2/public';
 config.development.apiImageStatic = 'https://api.e-asis.com/static';
 config.development.storeUrl = 'https://api.e-asis.com/';


// config.development.apiUrl = 'http://localhost:4502/api/v2';
// config.development.storeUrl = 'http://localhost:4502';
// config.development.apiImageStatic = 'http://localhost:4502/static';
// config.development.apiUrlPublic = 'http://localhost:4502/api/v2/public';

const currentConfig = config[process.env.NODE_ENV];

const axiosCallApi = axios.create({
  baseURL: config[process.env.NODE_ENV].apiUrl,
  timeout: 25000
});

module.exports = {
  apiUrl: currentConfig.apiUrl,
  produtImgUrl: currentConfig.produtImgUrl,
  apiImageStatic: currentConfig.apiImageStatic,
  apiUrlPublic: currentConfig.apiUrlPublic,
  storeUrl: currentConfig.storeUrl,
  port: currentConfig.port,
  axiosCallApi
};
